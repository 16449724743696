import React from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom'; 

/* Include pages */
import Home from '../Pages/Home';
import R404 from '../Pages/R404';
import GetDataForTok from '../Pages/Forms/GetTokFrm';
import Ide from '../Pages/Ide';




const HeaderRotes = (props) => {
	// eslint-disable-next-line
	let [searchParams, setSearchParams] = useSearchParams();
	// eslint-enable-next-line

	let paramsURL;

	// All params of GET-request -> to array
	searchParams.forEach(function(value, key){
		if (value.length + key.length !== ""){
			paramsURL = {
				...paramsURL,
				[key]: value
			}
		};
	});


	return (
		<div>
			<Routes>

				<Route exact path="/" element={<Home get={paramsURL} />} />

				<Route path="/token" >
					<Route index element={<Home get={paramsURL} />} />
					{/* <Route path=":tokenId" element={<Home1 token={":tokenId"} get={paramsURL} />} /> */}
					<Route path=":tokenId" element={<GetDataForTok token={":tokenId"} get={paramsURL} />} />
				</Route>

				<Route path="/ex/:ids" element={<Ide />} />

				<Route path="*" element={<R404 />} />

				{/* <Route path="/docs" element={<Docs get={paramsURL} />} >
					<Route path=':tabs' element={<Invoice get={paramsURL} />} />
				</Route>

				<Route path="users" element={<Users get={paramsURL} />} />
				<Route path="about" element={<About get={paramsURL} />} />

				<Route path="contacts" element={<ContactSTD get={paramsURL} />} />

				<Route path="profile" element={<Profile get={paramsURL} />} >
					<Route path=":userId" element={<Prt name={'true'} get={paramsURL} />} />
				</Route> */}

				{/*<Route
					path="/:lang/"
					render={props => <Profile {...props} />}
				/>*/}

				{/*<Route path="/invoices" element={<Invoices />}>
						<Route path=":invoiceId" element={<Invoice />} />
						<Route path="sent" element={<SentInvoices />} />
				</Route>*/}
				{/*<Route path="invoices/:invoiceId" element={<Invoice />} />*/}


				
				{/* <Route path="invoices" element={<Invoices get={paramsURL} />}>
					<Route path=":invoiceId" element={<Invoice get={paramsURL} />} />
					<Route path="sent" element={<SentInvoices get={paramsURL} />} />
				</Route>

				<Route path="/about1" element={<About1 get={paramsURL}/>} >
					<Route path=":id" element={<About1 get={paramsURL}/>} />
					<Route path=":id/:el" element={<About1 get={paramsURL}/>} />
				</Route>

				<Route path="/calendar" element={<Calendar get={paramsURL}/>} >
          <Route path=":id" element={<Calendar get={paramsURL}/>} />
          <Route path=":id/:el" element={<Calendar get={paramsURL}/>} />
        </Route>

				<Route path="*" element={<R404 />} /> */}

			</Routes>
		</div>
	)
}

export default HeaderRotes;
