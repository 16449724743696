/* File of global variables */

const DEBUG = false;

//const BACK_HOST= 'http://back.fpanel.localhost:8000/api/v1';
//const BACK_HOST = 'http://api.logsrv.ml/api/v1';
//const GET_DOK = 'http://api.logsrv.ml/api/v1/getdok';
//const BACK_HOST = 'https://bpanel.firmatec.pl/api/v1';
//const GET_DOK = 'https://bpanel.firmatec.pl/api/v1/getdok';

const PDF_PATH = "https://files.firmatec.pl/dokumenty/";

const BACK_HOST = () => {
	if (DEBUG){
		return 'http://back.fpanel.localhost:8000/api/v1';
	}
	return 'https://bpanel.firmatec.pl/api/v1';
};


export {BACK_HOST, /*GET_DOK,*/ PDF_PATH};