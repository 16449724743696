/* HOME  page */
import React from 'react';
import { useParams } from 'react-router-dom';
import {FormInpToken as FrmInpGet} from './Forms/MainForm';



const Home = (props) => {

	const params = useParams();

  return (
		<div className="container">
			<FrmInpGet
				show={true}
				token={params.tokenId}
				// onHide={() => {setModalShow(false); refreshPage()}}
			/>
		</div>
  )
}


export default Home;