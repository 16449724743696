import React from "react";


const Ide = () => {
	console.log("qqq");

	return (
		<div>
			This IDE-page
		</div>
	);
}

export default Ide;