import React from "react";
import { Button } from "react-bootstrap";
import { FiletypePdf, HouseDoor } from "react-bootstrap-icons";
import { useNavigate } from 'react-router-dom';

/* Button PDF-download (with props.lnk) */
export const BtnPDF = (props) => {
	if (props.lnk)
		return (
			<>
				<Button variant="outline-danger" href={props.lnk} target="_blank" size="sm">
					<FiletypePdf className="fs-4" />
				</Button>
			</>
		);
	return;
};


/* Button return to home */
export const HomeBtn = () => {
	let navigate = useNavigate();
	async function SubmitMainPage (event) {
		event.preventDefault();
		// 👇️ redirect
		navigate('/');
	}
	return (
		<Button onClick={SubmitMainPage} className="bg-gradient btn-rounded" style={{padding: "6px 8px"}}>
			<div>
				<HouseDoor />&nbsp;Wprowadź nowy token
			</div>
		</Button>
	);
}