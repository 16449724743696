
import React from "react";
import { Modal, Alert, Col, Row, Container, Table } from "react-bootstrap";
import { PDF_PATH } from "../../../js/Globals";
import { BtnPDF, HomeBtn } from "../Buttons/Buttons";


/* DETAIL OF FACTURA */
const FactDtl = (props) => {
	//const [wartnet, setWartnet] = useState(0);

	let doc_dtl = props.data;

	/* path fr downloading PDF */  
	let dt = new Date(doc_dtl[0]['DateF']);
	let dop_PDF = 
		dt.getFullYear() + 
		"/" + 
		("0" + (dt.getMonth() + 1)).slice(-2) +
		("0" + dt.getDate()).slice(-2) + 
		"/";

	let wartnet = 0;
	let vat = 0;
	let wartbrut = 0;
	/* FOREACH! */
	doc_dtl.forEach(el => {
		wartnet += parseFloat(el.CN);
		vat += parseFloat(el.VAT);
		wartbrut += parseFloat(el.WartBrut);
	});

	let pattern = /[^0-9a-zA-Z]+/ig;
	let lnk = (doc_dtl[0]["Te"]["PDF"] === "T" ? PDF_PATH + dop_PDF + doc_dtl[0]['fRej'].replace(pattern,'_', ) + "_" + doc_dtl[0]['ID'].slice(doc_dtl[0]['ID'].lastIndexOf('.')+1) + ".pdf" : 0);


	return (
		<Modal
			className="fade"
			show = {props.show}
			backdrop="static"
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="rounded-3 panel-heading bg-primary bg-gradient opacity-75 p-2">
					<b>DETAILS: {doc_dtl[0]['fRej']}</b>
			</Modal.Header>
			{/* <Modal.Header className="rounded-3 p-0 m-0">
				<Container className="p-0 m-0">
				<Alert variant="primary" className="m-0 p-2 bg-gradient">
					<b>DETAIL</b>
				</Alert>
				</Container>
			</Modal.Header> */}

			<Modal.Body className="p-3">

				{/* Error message if exist */}
				<Alert
					variant="danger"
					show={(props.data.error !== undefined)}
					className="mb-0 p-2 shadow"
				>
				</Alert>

				{/* Number an date of factura */}
				<Alert variant="info" className="bg-gradient shadow">
					<Row className="vcentr">
						<div className="col col-sm-8 col-md-8 col-lg-10 float-start vcentr">
							<div className="float-start">
								{doc_dtl[0]['Typ_doc']}: <b>{doc_dtl[0]['fRej']}</b>
							</div>
						</div>
						<div className="col-4 col-sm-4 col-md-4 col-lg-2">
							<div className="text-end vcentr">
								<b>{doc_dtl[0]['DateF'] || " --- "}</b>
								<input 
									type="hidden" 
									id="hdn_fact_pdf" 
									name="hdn_fact_pdf" 
									value={doc_dtl[0]["Te"]["PDF"] === "T" ? '1' : '0'}
									lnk={doc_dtl[0]["Te"]["PDF"] === "T" ? lnk : ""}
								/>
							</div>
						</div>
					</Row>
				</Alert>

				{/* Buyer info */}
				<Alert variant="warning" role="alert" className="shadow bg-gradient">
					{/* <Row className="container-fluid header-condensed"> */}
					<Row className="header-condensed">
						<Col className=" vcentrdiv">
							<b><u>Nabywca:</u> </b> 
							<b>{doc_dtl[0]['fnazw']}</b><br />
							{doc_dtl[0]['Kodp'] + " " + doc_dtl[0]['Mias']} <br />
							{doc_dtl[0]['Ulic']} <br />
							{((doc_dtl[0]['NIP'] !== "") ? "NIP: " + doc_dtl[0]['NIP'] : "")}
						</Col>
						<Col className="vcentrdiv">
							{/* <!--img src=\"../img/pdf_ico.png\"--> */}
						</Col>
					</Row>
				</Alert>



				{/* Details-table */}
				<Table hover responsive /*bordered*/ size="sm" className="table-condensed shadow rounded">

					{/* <thead className="rounded-3 thead-dark bg-secondary bg-gradient opacity-50"> */}
					{/* <thead className="table-dark opacity-50"> */}
					<thead className="table-active table-header-condensed">
						<tr className="">
							<th className="vcentr">Lp.</th> 
							<th className="vcentr">Nazwa towaru lub usługi</th> 
							<th className="text-center vcentr">Ilość</th> 
							<th className="text-center vcentr">JM</th> 
							<th className="text-center vcentr">Cena brutto</th> 
							<th className="text-center vcentr">Wart. netto</th>  
							<th className="text-center vcentr">VAT %</th>
							<th className="text-center vcentr">VAT</th>
							<th className="text-center vcentr">Wart. brutto</th>
						</tr>
					</thead>
					<tbody>
						{doc_dtl.map((h, i) => {
							return (
								<tr key={i}>
									<td className="vcentr text-center">{i+1} </td>
									<td className="vcentr">{h.Nazw} </td>
									<td className="text-center vcentr">{h.il}</td>
									<td className="text-center vcentr">{h.JM}</td>
									<td className="text-center vcentr">{h.CB}</td>
									<td className="text-center vcentr">{h.CN}</td>
									<td className="text-center vcentr">{h.NVat}</td>
									<td className="text-center vcentr">{h.VAT}</td>
									<td className="text-center vcentr">{h.WartBrut}</td>
								</tr>
							)}
						)}

						<tr>
							<td colSpan={5} className="table-active vcentr text-end font-monospace" name="Razem label"><b>Razem: </b></td>
							<td className="table-active vcentr text-center font-monospace" name="WartNetto sum"> <b>{wartnet.toFixed(2)}</b></td>
							<td className="table-active vcentr text-center font-monospace" name="Space">  </td>
							<td className="table-active vcentr text-center font-monospace" name="VAT sum"> <b>{vat.toFixed(2)}</b></td>
							<td className="table-active vcentr text-center font-monospace" name="WartBrutto sum"> <b>{wartbrut.toFixed(2)}</b></td>
						</tr>

					</tbody>
				</Table>

			</Modal.Body>


			<Modal.Footer className="p-1">
				<Container fluid /*className="p-1 m-0"*/>
					<Row>
						<Col>
							<BtnPDF lnk={lnk} />
							{/* <Button onClick={props.onHide} >
								Exit1
							</Button> */}
						</Col>
						<Col className="d-flex justify-content-end ">
							<HomeBtn />
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};






export { FactDtl };