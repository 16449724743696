import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';



// Spinner in modal
const SpinnerWait = (props) =>{
	return(
		<>
			<Modal
				{...props}
				backdrop="static"
				size="lg"
				centered
				className='fade bd-example-modal-lg'
				data-backdrop="static" data-keyboard="false" tabIndex="-1"
				contentClassName="bg-transparent border-0 position-relative"
			>
				<div className="position-absolute top-50 start-50 translate-middle">
					<Spinner 
						animation="grow" 
						role="status" 
						style={{ width: "7rem", height: "7rem" }}
					>
						{/* Some text will be here */}
					</Spinner>
				</div>
			</Modal>
		</>
	);
};

export { SpinnerWait };