import React from "react";
import { Modal, Alert, Col, Row, Container } from "react-bootstrap";
import parse from 'html-react-parser';
import { HomeBtn } from "../Buttons/Buttons";
import {ExclamationTriangleFill} from "react-bootstrap-icons";


/* ERROR MESSAGE */
const ErrMsg = (props) => {

	return (
		<Modal
			className="fade"
			show = {props.show}
			backdrop="static"
			//size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="rounded-3 panel-heading bg-danger bg-gradient opacity-75 p-2">
				<b>Błąd</b>
			</Modal.Header>

			<Modal.Body className="p-3">
				{/* Error message if exist */}
				<Alert
					variant="danger"
					show={(props.data !== undefined)}
					className="mb-0"
				>
					<span>
					<ExclamationTriangleFill className=" align-items-center"/> &nbsp;
						{parse(props.data.replace("\n", "<br />"))}
					</span>
				</Alert>
			</Modal.Body>

			<Modal.Footer className="p-1">
				<Container fluid className="p-1 m-0">
					<Row>
						<Col>
							{/* <Button onClick={props.onHide} >
								Exit1
							</Button> */}
						</Col>
						<Col className="d-flex justify-content-end">
							<HomeBtn />
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};

export {ErrMsg};