import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; 

/* Routes for my application */
import HeaderRotes from './Components/Routes';

/* Include CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

//https://mariosfakiolas.com/blog/my-react-components-render-twice-and-drive-me-crazy/
function App() {
  return (
    <div style={{ 
        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bg/'+ getRandomInt(10) + '.jpg'})`,
        opacity: 0.6,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh'
      }}
    >
      { /* ROTES!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
      {/* <Router basename={"/22"}> */}
      <Router /*basename="/react"*/>
        <HeaderRotes />
      </Router>
    </div>
  );
}

export default App;
