import React, {useState} from 'react';
import { Button, Alert, Modal, Form, Container, FloatingLabel } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckSquare } from "react-bootstrap-icons";


export const FormInpToken = (props) => {

	const params = useParams();
	//console.log(params.tokenId || "params.tokenId is undefined");

	const [token, setToken] = useState(params.tokenId || "");
	
	const ChgTokenInput = (event) => {
		//  Get input value from "event"
		const newStr = event.target.value.replace(/[^a-zA-Z-]/g, "");
		setToken(newStr);
	};

	let navigate = useNavigate();
	async function handleSubmit (event) {
		event.preventDefault();
		if (!token)
			return;
		// 👇️ redirect
		navigate('/token/' + token, {token: token});
	}

	return(
		<div>
			<Modal
				{...props}
				backdrop="static"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				//className='opacity-75'
			>
				{/* Body */}
				<Modal.Body className='p-2'>
					<Alert className='mb-0' variant="success">
						<Alert.Heading>Kod dokumentu</Alert.Heading>
						<div className='mb-3'>
							Podaj kod dokumentu nadany przez serwis. Znajdziesz go w mailu, wiadomości SMS lub na wydruku w opisie dokumentu.
						</div>
						<Form onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="formToken">
								<FloatingLabel
									controlId="floatingInput"
									label="Kod dokumentu"
									className="mb-3"
								>
									<Form.Control 
										type="text" 
										placeholder="Kod dokumentu" 
										maxLength="7"
										defaultValue={params.tokenId} 
										onChange={ChgTokenInput} 
										value={token}
										onKeyDown={e => {/[^a-zA-Z-]/g.test(e.key) && e.preventDefault()}}
										pattern="[a-zA-Z-]+"
										title="Only letters!"
									/>
									<Form.Text className="text-muted">
										Podaj kod dokumentu z iBizness
									</Form.Text>
								</FloatingLabel>
							</Form.Group>
						</Form>

						<Container className='d-grid gap-1 col mx-auto container'>
							<Button variant='primary' onClick={handleSubmit} className="bg-gradient btn-rounded" style={{padding: "5px 3px"}}>
							<div className="d-flex justify-content-center align-items-center">
								<CheckSquare />&nbsp; Sprawdź
							</div>
							</Button>
						</Container>

					</Alert>
				</Modal.Body>

			</Modal>
		</div>
	);
};