/* Page of tokens */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BACK_HOST } from '../../js/Globals';
import { SpinnerWait } from './SpinnerWait';

import { FactDtl } from "./Modals/FacturaDtl";
import { ZlecDtl } from "./Modals/ZlecDtl";
import { ErrMsg } from "./Modals/ErrorMsg";

import axios from 'axios';



const GetDataForTok = (props) => {

	const params = useParams();

	const [isLoading, setLoading] = useState(true);
	const [respdata, setRespdata] = useState();

	const [FrmDtl, setFrmDtl] = useState(false);
	const ShowFrmDtl = () => setFrmDtl(true);
	const HideFrmDtl = () => setFrmDtl(false);

	useEffect(() => {
		let url = `${BACK_HOST()}/getdok/${params.tokenId.toUpperCase()}`;
		
		axios.get(url, {
			params: {
				hash: 'qwerty123445', 
				isval: 'second value' 
			}, 
			headers: {
				'Accept': 'application/json',
				'Accept-Language': 'pl, en',
				'Access-Control-Allow-Origin': '*'
			}
		})
		.then(response => {
			// Set data for response
			let data=response.data;
			setRespdata({data});
		})
		.catch(err => {
			let error = (!err.response ? 'Error: Network Error': (err.response.data.error.message || err.message));
			// Set value ERROR
			setRespdata({error});
		})
		.finally(response => {
			// Close loadinf-form
			setLoading(false);
			// Show form of factura
			ShowFrmDtl();
		});
		// eslint-disable-next-line
	}, []);
		// eslint-enable-next-line
	
	if (!params.tokenId)
		return "No token!";



	/* STILL LOADING... */
	if (isLoading) {
		//console.debug("renders: Loading...");
		return (
			<>
				{ <SpinnerWait show={true} /> }
			</>
		)
	}



	//let data = props.data;
	//get data from request
	if (FrmDtl) {
		/* Got error */
		if (respdata.error !== undefined)
			//return ErrorMsg(props);
			return(
				/* Reurn ERROR modal */
				<ErrMsg data={respdata.error} show={FrmDtl} />
			);

		/* Got some data */
		if (respdata.data !== undefined) {
			switch (respdata.data[0]['doc_type'].toUpperCase()) {
				case 'F':
					/* Return detail of factura modal */
					return(
						<FactDtl data={respdata.data} show={FrmDtl} onHide={() => HideFrmDtl()}/>
					);
					case 'Z':
						/* Return detail of factura modal */
						return(
							<ZlecDtl data={respdata.data} show={FrmDtl} onHide={() => HideFrmDtl()}/>
						);
					default:
					return(
						<ErrMsg data={"Niepravidlowy typ dokumentu: <b>" + respdata.data[0]['doc_type'] + "</b>"} show={FrmDtl} />
					);
			}
		}
		
		return (<ErrMsg data={"Nieznany błąd"} show={FrmDtl} />);
	}


	// return (
	// 	<div>
			
	// 		{ FrmDtl ? <FormDtl show={FrmDtl} onHide={() => HideFrmDtl()} data={respdata || "Waiting for answer"} /> : null }

	// 	</div>
	// )
}



export default GetDataForTok;