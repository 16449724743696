
import React, {useState} from "react";
import { Modal, Alert, Col, Row, Container, Table, Card, Collapse, Badge } from "react-bootstrap";
import { PDF_PATH } from "../../../js/Globals";
import { DashSquareFill, PlusSquare } from "react-bootstrap-icons";
import { Date_from_str, Str_from_date, FormatDate } from "../../../js/DateFunc";
import { BtnPDF, HomeBtn } from "../Buttons/Buttons";

/* DETAIL OF ZLECENIE */
const ZlecDtl = (props) => {
	//const [wartnet, setWartnet] = useState(0);

	const [dtlOpen, setdtlOpen] = useState(false);
	const [partsOpen, setpartsOpen] = useState(false);


	let doc_dtl = props.data[0];


	if (doc_dtl['Tpl'].length === 8)
		doc_dtl['Tpl'] = doc_dtl['Tpl'] + doc_dtl['BankS']

	const zlec_date = Str_from_date(Date_from_str(doc_dtl['Tpl']), 'i');
	

	/* path fr downloading PDF */  
	let dt = Date_from_str(doc_dtl['DWy']);
	let dop_PDF = 
		dt.getFullYear() + 
		"/" + 
		("0" + (dt.getMonth() + 1)).slice(-2) +
		("0" + dt.getDate()).slice(-2) + 
		"/";

	let pattern = /[^0-9a-zA-Z]+/ig;
	let pdf_lnk = (doc_dtl["Te"]["PDF"] === "T" ? PDF_PATH + dop_PDF + doc_dtl['fRej'].replace(pattern,'_', ) + "_" + doc_dtl['ID'].slice(doc_dtl['ID'].lastIndexOf('.')+1) + ".pdf" : 0);
	//const FactNr = (doc_dtl['NrK']['FIR']) ? doc_dtl['NrK']['FIR'] : "";

	return (

		<Modal
			className="fade"
			show = {props.show}
			backdrop="static"
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="rounded-3 panel-heading bg-primary bg-gradient opacity-75 p-2">
					<b>DETAILS: {doc_dtl['NrR']}</b>
			</Modal.Header>

			<Modal.Body className="p-3">

				{/* Error message if exist */}
				<Alert
					variant="danger"
					show={(props.data.error !== undefined)}
					className="mb-0 p-2 shadow"
				>
				</Alert>


				{/* {JSON.stringify(props.data)} */}

				{/* Number and date of factura */}
				<Alert variant="info" className="bg-info bg-opacity-25 bg-gradient shadow">
					<Row className="vcentr">
						<div className="col col-sm-7 col-md-7 col-lg-8 float-start vcentr">
							<div className="float-start">
								Zlecenie: <b>{doc_dtl['NrR']}</b>
							</div>
						</div>
						<div className="col col-sm-5 col-md-5 col-lg-4">
							<div className="text-end vcentr">
								<b>{zlec_date || " --- "}</b>
							</div>
						</div>
					</Row>
				</Alert>

				<input name="hiddenInput" value={"ghjhgf"} type="hidden" />




				{/* Klient Name and address */}
				<Alert variant="warning" className="bg-warning bg-opacity-25 bg-gradient shadow  ">
					<Row className="vcentr">
						<div className="col-12 col-sm-10 col-md-10 col-lg-11 float-start vcentr">
							<div className="float-start">
								<b><u>Klient:</u> </b>
								<GetAddress obj={doc_dtl} />
							</div>
						</div>
						<div className="col col-sm-2 col-md-2 col-lg-1">
							<div className="text-end vcentr">
								{/* <b>{zlec_date || " --- "}</b> */}
							</div>
						</div>
					</Row>
				</Alert>



				{/* Status, details and icons */}
				<div className="shadow p-0 rounded bg-gradient" style={{backgroundColor: doc_dtl['defsSZL']['color']}}>
					{/* Opacity */}
					<Alert className="bg-light bg-opacity-25 m-0 p-0 rounded">
						{/* Container */}
						<Row className="vcentr container p-2 pe-0" id={"clr_" + doc_dtl['ID'].replace(/[.]+/ig,'_')} >
							<Col className="col-12 col-xs-12 col-md-5 col-lg-5 float-start vcentr">
								<div className="float-start">
									Status:
									<b>
										{' ' + doc_dtl['Status']}
									</b>
								</div>
							</Col>
							<Col className="col-7 col-xs-7 col-md-5 col-lg-5 float-start vcentr">
								<div className="">
									Typ: <b>{" " + doc_dtl['Defn']}</b>
								</div>
							</Col>
							<Col className="col-5 col-xs-5 col-md-2 col-lg-2 p-0 float-end align-middle aligns-items-center">
								<div className="float-end align-middle aligns-items-center">
									<img src={process.env.PUBLIC_URL + "/assets/images/icons/napr_" + doc_dtl['Inf']['TYPN'][0] + ".png"} alt={doc_dtl['Inf']['TYPN']} />
									<img src={process.env.PUBLIC_URL + "/assets/images/icons/napr_" + doc_dtl['Inf']['MIEN'][0] + ".png"} alt={doc_dtl['Inf']['TYPN']} />
								</div>
							</Col>
						</Row>
					</Alert>
				</div>


				<br />

				{/* Details */}
				<Card>
					<Card.Header
						className="bg-success bg-gradient bg-opacity-25 shadow "
						onClick={() => setdtlOpen(!dtlOpen)}
						aria-controls="coll-dtl"
						aria-expanded={dtlOpen}
						as="h6"
					>
						{/* Icon of collapse */}
						<span className="d-inline-flex align-items-center">
							{dtlOpen ? <DashSquareFill className="opacity-50" /> : <PlusSquare className="opacity-75" />}&nbsp;
							{
								((doc_dtl['Inf']['NAZW'].trim() + doc_dtl['Inf']['MARK'].trim() + doc_dtl['Inf']['MD'].trim()).length > 5) ?
									<span> 
										<b> 
										{doc_dtl['Inf']['NAZW'].trim()}: {doc_dtl['Inf']['MARK'].trim()} 
										{doc_dtl['Inf']['MD'] !== '' && doc_dtl['Inf']['MARK'] !== '' ? ", " : ""}
										{doc_dtl['Inf']['MD'].trim()} 
										</b>
									</span>
								:
									""
									// <b> Brak Błąd nazwy urządzenia</b>
							}
						</span>
					</Card.Header>

					<Collapse 
						in={dtlOpen} 
						id="coll-dtl"
					>
						<Card.Body className="header-condensed p-1">
							<Row className="">
								<Col className="form-group">
									{/* Equioment */}
									<TableEquip obj={doc_dtl} />
								</Col>
							</Row>
						</Card.Body>
					</Collapse>
				</Card>

			<br />



			<OpisUsterki obj={doc_dtl} />
			<br />

				{/* Parts */}
				{doc_dtl['zlecSpec'].length ?
					<Card>
						<Card.Header
							className="bg-warning bg-gradient bg-opacity-25 shadow"
							onClick={() => setpartsOpen(!partsOpen)}
							aria-controls="coll-prts"
							aria-expanded={partsOpen}
							as="h6"
						>
							{/* Icon of collapse */}
							<span className="d-inline-flex align-items-center">
								{partsOpen ? <DashSquareFill className="opacity-50" /> : <PlusSquare className="opacity-75" />}&nbsp;
									<span> 
										<b>Pozycje</b>&nbsp;
										<sup><Badge bg="danger">{doc_dtl['zlecSpec'].length}</Badge></sup>									
									</span>
							</span>
						</Card.Header>

						<Collapse 
							in={partsOpen} 
							id="coll-prts"
						>
							<Card.Body className="header-condensed p-1">
								<Row className="">
									<Col className="form-group">
										{/* Equioment */}
										<TablePoz obj={doc_dtl.zlecSpec} />
									</Col>
								</Row>
							</Card.Body>
						</Collapse>
					</Card>
				:
					""
				}




			</Modal.Body>


			<Modal.Footer className="p-1">
				<Container fluid /*className="p-1 m-0"*/>
					<Row>
						<Col>
							<BtnPDF lnk={pdf_lnk} />
						</Col>
						<Col className="d-flex justify-content-end ">
							<HomeBtn />
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);

};






/**
 * Returns table of pozicji
 * 
 * @param {*} props 
 * @returns rendered table
 */
const TablePoz = (props) => {
	const obj = props.obj;
	return (
		<Table className="table-hover mb-0">
			<thead className="table-secondary bg-opacity-50 table-header-condensed">
				<tr className="active">
					<th className="text-center align-middle">Kod</th> 
					<th className="align-middle">Nazwa</th>  
					<th className="text-center align-middle">Ilość</th>
				</tr>
			</thead>
			<tbody className="table-group-divider">
			{obj.map((h, i) => {
				return (
					<tr key={i}>
						{/* <td className="vcentr text-center">{i+1} </td> */}
						<td className="text-center align-middle"><b>{h.Kod}</b></td>
						<td className="align-middle"><b>{h.Nazw}</b></td>
						<td className="text-center align-middle"><b>{h.il}</b></td>
					</tr>
				)}
			)}
			</tbody>
		</Table>
	);
}




/**
 * Returns table of equipment
 * 
 * @param {*} props 
 * @returns 
 */
const TableEquip = (props) => {
	const obj = props.obj;
	return (
		<Table className="table-hover table-sm mb-0">
			<thead className="table-secondary bg-opacity-50 table-header-condensed">
				<tr className="active">
					<th className="text-center vcentr">{obj['defsSERN']['SN']}</th> 
					<th className="text-center vcentr">{obj['defsSERN']['FN']}</th>  
					<th className="text-center vcentr">{obj['defsSERN']['GW']}</th>
					<th className="text-center vcentr">{obj['defsSERN']['DZ']}</th>
					<th className="text-center vcentr">{obj['defsSERN']['RP']}</th>
					<th className="text-center vcentr">{obj['defsSERN']['NR']}</th>
				</tr>
			</thead>
			<tbody className="table-group-divider">
				<tr>
					<td className="text-center">
						<b>
						{(obj['Inf']['SN']==="") ? "----" : obj['Inf']['SN'].trim()}
						</b>
					</td>
					<td className="text-center">
						<b>
						{(obj['Inf']['FN']==="") ? "----" : obj['Inf']['FN'].trim()}
						</b>
					</td>
					<td className="text-center">
						<b>
						{(obj['Inf']['GW']==="") ? "----" : obj['Inf']['GW'].trim()}
						</b>
					</td>
					<td className="text-center">
						<b>
						{(obj['Inf']['DZ']==="") ? "----" : obj['Inf']['DZ'].trim()}
						</b>
					</td>
					<td className="text-center">
						<b>
						{(obj['Inf']['RP']==="") ? "----" : obj['Inf']['RP'].trim()}
						</b>
					</td>
					<td className="text-center">
						<b>
						{(obj['Inf']['NR']==="") ? "----" : obj['Inf']['NR'].trim()}
						</b>
					</td>
				</tr>
			</tbody>
		</Table>
	);
}



/**
 * Returns extended descrpiption
 * 
 * @param {*} props 
 * @returns 
 */
const OpisUsterki = (props) => {
	const obj = props.obj
	return (
		<>
			<Row className="vcentr">
				<Col className="col-sm-6 col-md-8 col-lg-8 pull-left vcentr table-responsive">
					<small><u>Opis Usterki:</u></small>
						<Card>
							<Card.Header>
								<pre className="m-1"><b>{obj['notk']['OP']}</b></pre>
							</Card.Header>
						</Card>
					<small><u>Uwagi / Zakres naprawy:</u></small>
						<Card>
							<Card.Header>
								<pre className="m-1"><b>{obj['notk']['UW']}</b></pre>
							</Card.Header>
						</Card>
				</Col>

				<Col className="col-sm-6 col-md-4 col-lg-4 vcentr table-responsive">
					<Table className="table-hover table-sm">
						<thead className="table-secondary bg-opacity-25">
							<tr className="active">
								<th className="vcentr">
									<small>Przeglądy</small>
								</th>
								<th className="text-center vcentr">
									<small>Data</small>
								</th>
								<th className="text-center vcentr">
									<small>Przebieg</small>
								</th>
							</tr>				
						</thead>
						<tbody>
							<tr disabled={true}>
								<td className="vcentr isDisabled">
									<label>
										<input type="checkbox" 
											id="Dpl_chk" 
											value={obj['Dpl'] ? 1 : 0} 
											defaultChecked={obj['Dpl']}
											doctype={obj['doc_type']}
											doc_id={obj['ID']}
											pole="Dpl" 
											elem="---" 
											disabled={true}
											/>
										<small> Wykonano</small>
									</label>
								</td>
								<td className="nwrap vcentr text-center isDisabled" id="Dpl_dt">
									{obj['Dpl'] ? Str_from_date(Date_from_str(obj['Dpl'])) : FormatDate(new Date())}
								</td>
								<td id='Dpl_prz' className='text-center vcentr isDisabled'>
									{obj['N4'] || 0}
								</td>
							</tr>

							<tr disabled={true}>
								<td className="vcentr isDisabled">
									<label>
										<input type="checkbox" 
											id='Dnext_chk'
											value={obj['Dnext'] ? 1 : 0} 
											defaultChecked={obj['Dnext']}
											doctype={obj['doc_type']}
											doc_id={obj['ID']}
											pole="Dnext" 
											elem="---" 
											disabled={true}
											/>
										<small> Następny</small>
									</label>
								</td>
								<td className="nwrap vcentr text-center isDisabled" id="Dnext_dt">
									{obj['Dnext'] ? Str_from_date(Date_from_str(obj['Dnext'])) : FormatDate(new Date())}
								</td>
								<td id='Dnext_prz' className='text-center vcentr isDisabled'>
									{obj['N3'] || 0}
								</td>
							</tr>

						</tbody>
					</Table>
				</Col>
			</Row>
		</>
	);
}











/**
 * Render address of client with phone-numbers
 * 
 * @param {object} props 
 * @returns 
 */
const GetAddress = (props) => {
	const obj = props.obj;
	
	const len_notk = ((obj['notk']['K'] + obj['notk']['M'] + obj['notk']['A'] + obj['notk']['U']).length > 5);
	const client_name = len_notk ? 
			((obj['notk']['A'].length) ? <b>{obj['notk']['A']}</b> : null) 
		: 
			((obj['Alias'].length) ? <b>{obj['Alias']} </b> : null)
	const addr = len_notk ? 
			(obj['notk']['K']+ " " + obj['notk']['M'] + (obj['notk']['U'] ? ", " + obj['notk']['U'] : "")) 
		: 
			(obj['Kodp'] + " " + obj['Mias'] + ", " + obj['Ulic'])

	return (
		<>
			{client_name}

			{/* Telephons */}
			{(obj['kraj'] || obj['DJ']) ? 
				<>
					<TelePhons tel={obj['kraj'] ? obj['kraj'].toString() : null} tel2={obj['DJ'] ? obj['DJ'].toString() : null} />
					<br />
				</> 
				: 
					""
				}

			{/* Coordinates */}
			{obj["lat"] + obj["lon"] ? 
				<>
					<img src={process.env.PUBLIC_URL + "/assets/images/icons/lok.png"} alt="Locate" /> 
					<a href={"http://www.google.com/maps/place/" + obj['lat'] + "," + obj['lon']} target="_blank" rel="noreferrer" >
						{addr}
					</a>
				</>
			:
				addr
			}
		</>
	);
}




/**
 * Render telephos numbers with icon
 * 
 * @param {teleph1, teleph2} props 
 * @returns 
 */
const TelePhons = (props) => {
	const tel = props.tel;
	const tel2 = props.tel2;
	return (
		<>
		{(tel !== null && tel.length) ?
				<>
					<img src={process.env.PUBLIC_URL + "/assets/images/icons/tel.png"} alt="Telephone" />&nbsp;
					<a href={"tel:" + tel}>{tel}</a>
				</>
			:
				null
		}
		{(tel2 !== null && tel2.length) ?
				<>
					, <a href={"tel:" + tel2}>{tel2}</a>
				</>
			:
				null
		}
		</>
	);
}







export { ZlecDtl };