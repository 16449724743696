

// Get unix timestamp from string
export const Date_from_str = (instr) => {

	const pair = Array.from(instr.toString().padEnd(14, '0').replace(" ", "0"))
	pair.splice(12, 0, ':')
	pair.splice(10, 0, ':')
	pair.splice(8, 0, 'T')
	pair.splice(6, 0, '-')
	pair.splice(4, 0, '-')
	
	return new Date(pair.join(''));
}



export const Str_from_date = (date, format = 'd') => {

	let outstr = 
		("0" + date.getDate()).slice(-2) + 
			"-" + 
		("0" + (date.getMonth() + 1)).slice(-2) +
			"-" +
		date.getFullYear();
	
	if (format === 'h' || format === 'i') {
		outstr += " " + ("0" + (date.getHours() + 1)).slice(-2);
	}

	if (format === 'i') {
		outstr += ":" + ("0" + (date.getMinutes() + 1)).slice(-2);
	}

		return outstr;
}




const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

export const FormatDate = (date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('-');
}